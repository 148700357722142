import {ref, reactive, toRefs, computed,watch,defineComponent, onMounted, getCurrentInstance,provide} from 'vue';
import WayBillCard from "../../dispatch/wayBill/WayBillCard";
import WayBillCarCard from "../../dispatch/wayBillCar/WayBillCarCard";
const AccountCardHelper = defineComponent({
    name:'AccountCard',
    title:'车次详情',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: false,
                modelPath: "/customerAccount"
            },
            //表单
            form:{costBean:{}},
            //表单验证规则
            rules: {}
        })
        onMounted(()=>{
        })
        //---------------------------computed---------------------------
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(data,addOrLoad,cardEngine)=>{
            dataObj.disabled=data.data.disabled;
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        //设置查询参数，本次到底是客户对账还是司机对账,quotationType从列表中来
        const setParam=(engineInst)=>{
            if(engineInst.engineParams.queryParam.quotationType)
            return {quotationType:engineInst.engineParams.queryParam.quotationType};
            else return {}
        }
        //查看报价单
        const viewQuotation=()=>{
            if(dataObj.form.driverQuotationFlag==1){//肯定是承运商报价单，而且是按跟客户结算百分比
                let title='客户报价单费用配置';
                utils.$$lghdUtils.buildQuotationPriceConfigDialog({id:dataObj.form.customerQuotationId,proxy:proxy,title:title});
            }else{
                let quotationType='客户报价单';
                if(dataObj.form.quotationType=='driver')quotationType='承运商报价单';
                // let title=quotationType+'['+dataObj.form.quotationCode+']'+'费用配置';
                let title=quotationType+'['+dataObj.form.quotationName+']'+'费用配置';
                utils.$$lghdUtils.buildQuotationPriceConfigDialog({id:dataObj.form.quotationId,proxy:proxy,title:title});
            }
        }
        //查看运单
        const viewWayBill=async ()=>{
            await utils.$$lghdUtils.buildCommonDialog({from:'AccountCard',id:dataObj.form.waybillId,title: "运单"+dataObj.form.waybillCode,dialogDiv:"WayBillCard",
                modelComp: WayBillCard,proxy:proxy});
        }
        //查看车次
        const viewWayBillCar=async ()=>{
            await utils.$$lghdUtils.buildCommonDialog({from:'AccountCard',id:dataObj.form.waybillCarId,title: "车次"+dataObj.form.carOrder,dialogDiv:"WayBillCarCard",
                modelComp: WayBillCarCard,proxy:proxy});
        }
        //确认对账
        const sureAccountHandler=async ()=>{
            await proxy.engine.doSave(false);
            utils.$$tools.configBox({
                msgContent:'确认执行该操作吗?',
                fn:async ()=> {
                    let res = await utils.$$api.postRequest({ url:dataObj.compParams.modelPath+ '/custom', params: {id:dataObj.form.id,operateType:'sureAccount',quotationType:dataObj.form.quotationType}});
                    if(res.result){
                        utils.$$tools.success({ message: res.msg });
                        if (proxy.engine.engineParams.ownerComp && proxy.engine.engineParams.ownerComp.queryHandler)proxy.engine.engineParams.ownerComp.queryHandler();
                        await proxy.engine.doAddOrLoad(proxy.engine.id);
                    }
                }
            });
        }
        return{
            ...toRefs(dataObj),beforeOpen,beforeSaveHandler,setParam,viewQuotation,viewWayBill,viewWayBillCar,sureAccountHandler
        }
    }
});
export default AccountCardHelper;